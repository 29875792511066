import { PostgrestError } from "@supabase/postgrest-js";
import React from "react";
import { client } from "./Supabase";
import { Id } from "./Id";

type Props = {
  id: Id;
  disabled?: boolean;
  onAttempt?: () => void;
  onSuccess?: (id: Id) => void;
  onFailure?: (reason: PostgrestError | Error) => void;
  className?: string;
};

export const TaskDelete = ({
  id,
  disabled,
  onAttempt = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  className,
}: Props) => {
  const deleteTask = async () => {
    onAttempt();

    const confirmed = window.confirm("Are you sure you want to delete?");

    if (!confirmed) {
      onFailure(new Error("User did not confirm."));
      return;
    }

    const { error } = await client.from("tasks").delete().eq("id", id);

    if (error) {
      onFailure(error);
    } else {
      onSuccess(id);
    }
  };

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={deleteTask}
      className={className}
    >
      🗑️
    </button>
  );
};
