import {
  AuthResponse,
  Session,
  SignInWithPasswordCredentials,
  createClient,
} from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { Database } from "../supabase/types/database";
import { Async, completed, loading } from "./Async";

const supabaseUrl = process.env.SUPABASE_URL || "";
const supabaseKey = process.env.SUPABASE_ANON_KEY || "";

export const client = createClient<Database>(supabaseUrl, supabaseKey);

export const useSession = (): Async<Error, Session | null> => {
  const [state, setState] = useState<Async<Error, Session | null>>(loading);

  useEffect(() => {
    const {
      data: { subscription },
    } = client.auth.onAuthStateChange((event, session) => {
      setState(completed(session));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
};

export const createSession = (
  credentials: SignInWithPasswordCredentials,
): Promise<AuthResponse> =>
  client.auth.signInWithPassword(credentials).then((res) => {
    if (res.error) {
      return client.auth.signUp(credentials);
    }

    return res;
  });

export const destroySession = () => client.auth.signOut();
