import React from "react";
import { createRoot } from "react-dom/client";
import Application from "./Application";

const container = window.document.getElementById("app");
if (!container) throw new Error("Missing container element.");

const root = createRoot(container);

root.render(<Application />);
