.root {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.root.open {
  align-items: start;
  cursor: unset;
  padding: 0.5rem 0.5rem;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.details {
  width: 100%;
}

.titleAndSave {
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
}

.completedControl {
}

.titleControl {
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.2em;
}

.title {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  font-size: 0.9rem;
}

.titleControl,
.title,
.descriptionControl {
  font-family: sans-serif;
}

.titleAndSave + .descriptionControl {
  margin-top: 0.25rem;
}

.descriptionControl {
  width: 100%;
  appearance: none;
  padding: 0.25rem 0;
  border: none;
  outline: 0;
  margin: 0;
  background: none;
  font-size: 0.8rem;
  resize: vertical;
}

.descriptionControl + .actions {
  margin-top: 0.25rem;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 0.5rem;
}

.deleteButton {
  appearance: none;
  padding: 0.25rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  outline: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  font-size: 0.8rem;
  cursor: pointer;
  transition: 250ms ease background-color;
}
.deleteButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
