import { Session } from "@supabase/gotrue-js";
import { PostgrestError } from "@supabase/postgrest-js";
import React, { useEffect, useState } from "react";
import { Id } from "./Id";
import { client } from "./Supabase";
import { Saveable, saved } from "./Saveable";
import { Task, fromDBRow } from "./Task";
import { TaskForm } from "./TaskForm";
import { TaskCreate } from "./TaskCreate";
import { useKeydown } from "./useKeydown";
import classNames from "classnames";
import styles from "./Tasks.module.css";

type TaskState = Saveable<Task, PostgrestError>;

type Props = {
  session: Session;
  className?: string;
};

export const Tasks = ({ session, className }: Props) => {
  const [tasks, setTasks] = useState<Map<Id, TaskState>>(new Map());
  const [openTaskId, setOpenTaskId] = useState<Id | null>(null);

  useKeydown("Escape", () => setOpenTaskId(null), []);

  useEffect(() => {
    client
      .from("tasks")
      .select()
      .then(({ data, error }) => {
        if (data) {
          setTasks(
            new Map([
              ...data.map((task): [Id, TaskState] => [
                task.id,
                saved(fromDBRow(task)),
              ]),
            ]),
          );
        }
      });
  }, []);

  return (
    <div className={classNames(styles.root, className)}>
      {[...tasks.entries()]
        .sort(([idA], [idB]) => (idA === idB ? 0 : idA < idB ? -1 : 1))
        .map(([id, task]) => (
          <TaskForm
            key={id}
            session={session}
            task={task}
            onChange={(task) => {
              setTasks((previous) => {
                previous.set(task.savedValue.id, task);
                return new Map(previous);
              });
            }}
            onDelete={(id) => {
              setTasks((previous) => {
                previous.delete(id);
                return new Map(previous);
              });
            }}
            onClick={() => {
              if (openTaskId !== id) {
                setOpenTaskId(id);
              }
            }}
            open={openTaskId === id}
          />
        ))}

      <TaskCreate
        onSuccess={(task) => {
          setTasks((previous) => {
            previous.set(task.id, saved(task));
            return new Map(previous);
          });
          setOpenTaskId(task.id);
        }}
      />
    </div>
  );
};
