import { Session } from "@supabase/supabase-js";
import { PostgrestError } from "@supabase/postgrest-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Task, equals, fromDBRow } from "./Task";
import { client } from "./Supabase";

type Props = {
  className?: string;
  session: Session;
  task: Task;
  onAttempt?: (task: Task) => void;
  onSuccess?: (task: Task) => void;
  onFailure?: (reason: PostgrestError, task: Task) => void;
};

export const TaskSave = ({
  className,
  session,
  task,
  onAttempt = () => {},
  onSuccess = () => {},
  onFailure = () => {},
}: Props) => {
  const [lastSaved, setLastSaved] = useState<Task>(task);
  const [saveStartedAt, setSaveStartedAt] = useState<Date | null>(null);
  const isSaving = !!saveStartedAt;

  const hasUnsavedChanges = useMemo(
    () => !equals(lastSaved, task),
    [lastSaved, task],
  );

  const saveTask = useCallback(async () => {
    if (!hasUnsavedChanges || isSaving) return;

    setSaveStartedAt(new Date());
    onAttempt(task);

    const { data, error } = await client
      .from("tasks")
      .update({
        completed_at: task.completedAt?.toISOString() || null,
        completed_by_id: task.completedAt ? session.user.id : null,
        description: task.description,
        due_at: task.dueAt?.toISOString() || null,
        scheduled_at: task.scheduledAt?.toISOString() || null,
        title: task.title,
      })
      .eq("id", task.id)
      .select()
      .single();

    setSaveStartedAt(null);

    if (data) {
      const savedTask = fromDBRow(data);
      setLastSaved(savedTask);
      onSuccess(savedTask);
    } else if (error) {
      onFailure(error, task);
    }
  }, [hasUnsavedChanges, isSaving, task, onAttempt, onSuccess, onFailure]);

  useEffect(() => {
    if (!hasUnsavedChanges || isSaving) return;

    const timeout = setTimeout(saveTask, 200);

    return () => clearTimeout(timeout);
  }, [isSaving, hasUnsavedChanges, saveTask]);

  if (hasUnsavedChanges || isSaving) {
    return <span className={className}>💾</span>;
  }
};
