import React, { ChangeEvent, useCallback } from "react";
import { ControlProps } from "../ControlProps";
import { valid } from "../Validated";
import classNames from "classnames";
import styles from "./TitleControl.module.css";

interface Props extends ControlProps<string> {
  className?: string;
  placeholder?: string;
}

export const TitleControl = ({
  value,
  onChange,
  className,
  ...props
}: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(valid(event.target.value));
    },
    [onChange],
  );

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.spacer}>{value.value}</span>

      <textarea
        value={value.value}
        onChange={handleChange}
        className={styles.input}
        {...props}
      />
    </div>
  );
};
