import { useEffect } from "react";

export const useKeydown = (
  key: string,
  effect: () => void,
  deps: Array<unknown>,
) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === key) effect();
    };

    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  }, [key, ...deps]);
};
