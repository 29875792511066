.root {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: 250ms ease background-color;
}
.root:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.label {
  appearance: none;
  padding: 0;
  border: none;
  border-radius: 0;
  margin: 0;
  background: none;
  font-family: sans-serif;
  font-size: 0.8rem;
  cursor: pointer;
}

.input {
  height: 100%;
}
.input > div {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.clearButton {
  appearance: none;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  margin: 0 0 0 0.3rem;
  font-size: 0.35rem;
  line-height: 1rem;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: 250ms ease background-color;
}
.clearButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
