// Types

export type Loading = {
  tag: "Loading";
};

type Completed<T> = {
  tag: "Completed";
  result: T;
};

type Failed<E> = {
  tag: "Failed";
  reason: E;
};

export type Async<E, T> = Loading | Completed<T> | Failed<E>;

// Constructors

export const loading: Loading = {
  tag: "Loading",
};

export const completed = <T>(result: T): Completed<T> => ({
  tag: "Completed",
  result,
});

export const failed = <E>(reason: E): Failed<E> => ({
  tag: "Failed",
  reason,
});

// Refiners

export const isLoading = (a: Async<unknown, unknown>): a is Loading =>
  a.tag === "Loading";

export const isCompleted = <T>(a: Async<unknown, T>): a is Completed<T> =>
  a.tag === "Completed";

export const isFailed = <E>(a: Async<E, unknown>): a is Failed<E> =>
  a.tag === "Failed";
