import React, { ChangeEvent, useCallback } from "react";
import { ControlProps } from "./ControlProps";
import { valid } from "./Validated";

interface Props extends ControlProps<string> {
  className?: string;
  placeholder?: string;
}

export const RichTextControl = ({ value, onChange, ...props }: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(valid(event.target.value));
    },
    [onChange],
  );

  return <textarea value={value.value} onChange={handleChange} {...props} />;
};
