import React from "react";
import { destroySession, useSession } from "./Supabase";
import { AuthenticationForm } from "./AuthenticationForm";
import { Tasks } from "./Tasks";

const Application = () => {
  const session = useSession();

  switch (session.tag) {
    case "Loading":
      return (
        <div>
          <p>Loading...</p>
        </div>
      );

    case "Failed":
      return (
        <div>
          <p>Something went wrong.</p>
          <p>{session.reason.message}</p>
        </div>
      );

    case "Completed": {
      if (!session.result) {
        return <AuthenticationForm />;
      }

      return (
        <div>
          <p>User Id: {session.result.user.id}</p>
          <button onClick={destroySession}>Sign Out</button>
          <Tasks session={session.result} />
        </div>
      );
    }
  }
};

export default Application;
