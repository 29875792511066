import React from "react";
import { ControlProps } from "./ControlProps";
import { EmailAddress, validate } from "./EmailAddress";

interface EmailControlProps extends ControlProps<string, EmailAddress> {
  className?: string;
}

export const EmailControl = ({
  value,
  onChange,
  ...props
}: EmailControlProps) => (
  <input
    type="email"
    value={value.value}
    onChange={(event) => onChange(validate(event.target.value))}
    {...props}
  />
);
