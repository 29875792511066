import { Session, User } from "@supabase/gotrue-js";
import React, { FormEvent, useState } from "react";
import { createSession } from "./Supabase";
import { EmailAddress } from "./EmailAddress";
import { Password } from "./Password";
import { invalid } from "./Validated";
import { areValid, Fields, setField } from "./Form/Fields";
import { unsaved, saving, failed, Value } from "./Form/Value";
import { EmailControl } from "./EmailControl";
import { PasswordControl } from "./PasswordControl";

type AuthenticationFields = Fields<
  { email: string; password: string },
  { email: EmailAddress; password: Password }
>;

const initialFields: AuthenticationFields = {
  email: invalid("", new Error("Email is required.")),
  password: invalid("", new Error("Password is required.")),
};

export const AuthenticationForm = () => {
  const [fields, setFields] = useState<AuthenticationFields>(initialFields);
  const [value, setValue] = useState<Value<[User, Session]>>(unsaved);

  function changeField<K extends keyof AuthenticationFields>(
    key: K,
  ): (value: AuthenticationFields[K]) => void {
    return (value) => {
      setFields(setField(key, value));
    };
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!areValid(fields)) return;

    setValue(saving());

    const credentials = {
      email: fields.email.value,
      password: fields.password.value,
    };

    createSession(credentials).then(({ error }) => {
      if (error) {
        setValue(failed(error));
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Email</label>
        <EmailControl value={fields.email} onChange={changeField("email")} />
      </div>
      <div>
        <label>Password</label>
        <PasswordControl
          value={fields.password}
          onChange={changeField("password")}
        />
      </div>

      <button disabled={!areValid(fields)} type="submit">
        Sign In
      </button>

      {value.tag === "Failed" ? <p>{value.reason.message}</p> : null}
    </form>
  );
};
