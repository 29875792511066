export type Saved<V> = {
  tag: "Saved";
  savedValue: V;
};
export const saved = <V>(savedValue: V): Saved<V> => ({
  tag: "Saved",
  savedValue,
});

export type Saving<V> = {
  tag: "Saving";
  savedValue: V;
  newValue: V;
};
export const saving = <V>(savedValue: V, newValue: V): Saving<V> => ({
  tag: "Saving",
  savedValue,
  newValue,
});

export type Failed<V, E = Error> = {
  tag: "Failed";
  reason: E;
  savedValue: V;
  newValue: V;
};
export const failed = <V, E = Error>(
  reason: E,
  savedValue: V,
  newValue: V,
): Failed<V, E> => ({
  tag: "Failed",
  reason,
  savedValue,
  newValue,
});

export type Saveable<V, E = Error> = Saved<V> | Saving<V> | Failed<V, E>;
