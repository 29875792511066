.root {
  display: grid;
}

.spacer {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.input {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  outline: 0;
  resize: none;
  overflow: hidden;
}

.input,
.spacer {
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
  min-height: 1rem;
}
