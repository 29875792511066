export type Unsaved = {
  tag: "Unsaved";
  savedValue: null;
};
export const unsaved: Unsaved = {
  tag: "Unsaved",
  savedValue: null,
};

export type Saved<V> = {
  tag: "Saved";
  savedValue: V;
};
export const saved = <V>(savedValue: V): Saved<V> => ({
  tag: "Saved",
  savedValue,
});

export type Saving<V> = {
  tag: "Saving";
  savedValue: V | null;
};
export const saving = <V>(savedValue: V | null = null): Saving<V> => ({
  tag: "Saving",
  savedValue,
});

export type Failed<V> = {
  tag: "Failed";
  reason: Error;
  savedValue: V | null;
};
export const failed = <V>(
  reason: Error,
  savedValue: V | null = null,
): Failed<V> => ({
  tag: "Failed",
  reason,
  savedValue,
});

export type Value<V> = Unsaved | Saved<V> | Saving<V> | Failed<V>;
