export type Invalid<T> = {
  tag: "Invalid";
  value: T;
  reason: Error;
};

export type Valid<T> = {
  tag: "Valid";
  value: T;
};

export type Validated<I, V extends I = I> = Invalid<I> | Valid<V>;

export const invalid = <T>(value: T, reason: Error): Invalid<T> => ({
  tag: "Invalid",
  value,
  reason,
});

export const valid = <T>(value: T): Valid<T> => ({
  tag: "Valid",
  value,
});

export const isValid = <I, V extends I = I>(
  value: Validated<I, V>,
): value is Valid<V> => value.tag === "Valid";

export const isInvalid = <I, V extends I = I>(
  value: Validated<I, V>,
): value is Invalid<V> => value.tag === "Invalid";

export const createValidator =
  <I, V extends I = I>(
    predicate: (value: I) => value is V,
    reason: (value: I) => Error,
  ) =>
  (value: I): Validated<I, V> =>
    predicate(value) ? valid(value) : invalid(value, reason(value));
