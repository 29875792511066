import React, { ChangeEvent, MouseEventHandler, useCallback } from "react";
import { ControlProps } from "./ControlProps";
import { valid } from "./Validated";
import styles from "./BooleanControl.module.css";
import classNames from "classnames";

interface Props extends ControlProps<boolean> {
  className?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const BooleanControl = ({
  value,
  onChange,
  className,
  disabled,
  onClick = () => {},
}: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(valid(event.target.checked));
    },
    [onChange],
  );

  return (
    <input
      className={classNames(styles.root, className)}
      type="checkbox"
      checked={value.value}
      onChange={handleChange}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
