import { Session } from "@supabase/gotrue-js";
import { PostgrestError } from "@supabase/postgrest-js";
import classNames from "classnames";
import React, { MouseEventHandler, useState } from "react";
import { Saveable, failed, saved, saving } from "../Saveable";
import { Fields, setField } from "../Form/Fields";
import { valid } from "../Validated";
import { Task } from "../Task";
import { Id } from "../Id";
import { TaskDelete } from "../TaskDelete";
import { RichTextControl } from "../RichTextControl";
import { DateControl } from "../DateControl";
import { TaskSave } from "../TaskSave";
import { TitleControl } from "./TitleControl";
import { CompletedControl } from "./CompletedControl";
import styles from "./TaskForm.module.css";

type Props = {
  session: Session;
  task: Saveable<Task, PostgrestError>;
  onChange: (task: Saveable<Task, PostgrestError>) => void;
  onDelete: (id: Id) => void;
  onClick: MouseEventHandler<HTMLDivElement>;
  open?: boolean;
  className?: string;
};

type TaskFields = Fields<{
  completedAt: Date | null;
  description: string;
  dueAt: Date | null;
  scheduledAt: Date | null;
  title: string;
}>;

const initialFields = (task: Task): TaskFields => ({
  completedAt: valid(task.completedAt),
  description: valid(task.description),
  dueAt: valid(task.dueAt),
  scheduledAt: valid(task.scheduledAt),
  title: valid(task.title),
});

export const TaskForm = ({
  session,
  task,
  onChange,
  onDelete,
  onClick,
  open,
  className,
}: Props) => {
  const [fields, setFields] = useState(initialFields(task.savedValue));

  return open ? (
    <div className={classNames(styles.root, styles.open, className)}>
      <CompletedControl
        className={styles.completedControl}
        value={fields.completedAt}
        onChange={(value) => setFields(setField("completedAt", value))}
      />

      <div className={styles.details}>
        <div className={styles.titleAndSave}>
          <TitleControl
            className={styles.titleControl}
            placeholder="New Task"
            value={fields.title}
            onChange={(value) => setFields(setField("title", value))}
          />

          <TaskSave
            session={session}
            task={{
              ...task.savedValue,
              completedAt: fields.completedAt.value,
              description: fields.description.value,
              dueAt: fields.dueAt.value,
              scheduledAt: fields.scheduledAt.value,
              title: fields.title.value,
            }}
            onAttempt={(newTask) => {
              onChange(saving(task.savedValue, newTask));
            }}
            onSuccess={(newTask) => {
              onChange(saved(newTask));
            }}
            onFailure={(reason, newTask) => {
              onChange(failed(reason, task.savedValue, newTask));
            }}
          />
        </div>

        <RichTextControl
          className={styles.descriptionControl}
          value={fields.description}
          onChange={(value) => setFields(setField("description", value))}
          placeholder="Notes"
        />

        <div className={styles.actions}>
          <DateControl
            value={fields.scheduledAt}
            onChange={(value) => setFields(setField("scheduledAt", value))}
            label="When"
          />

          <DateControl
            value={fields.dueAt}
            onChange={(value) => setFields(setField("dueAt", value))}
            label="Due"
          />

          <TaskDelete
            id={task.savedValue.id}
            disabled={task.tag === "Saving"}
            onSuccess={onDelete}
            className={styles.deleteButton}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames(styles.root, className)} onClick={onClick}>
      <CompletedControl
        className={styles.completedControl}
        value={fields.completedAt}
        onChange={(value) => setFields(setField("completedAt", value))}
        onClick={(event) => event.stopPropagation()}
      />

      <p className={styles.title}>{task.savedValue.title}</p>

      <TaskSave
        session={session}
        task={{
          ...task.savedValue,
          completedAt: fields.completedAt.value,
          description: fields.description.value,
          dueAt: fields.dueAt.value,
          scheduledAt: fields.scheduledAt.value,
          title: fields.title.value,
        }}
        onAttempt={(newTask) => onChange(saving(task.savedValue, newTask))}
        onSuccess={(newTask) => onChange(saved(newTask))}
        onFailure={(reason, newTask) =>
          onChange(failed(reason, task.savedValue, newTask))
        }
      />
    </div>
  );
};
