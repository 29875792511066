import { PostgrestError } from "@supabase/postgrest-js";
import React from "react";
import { client } from "./Supabase";
import { Task, fromDBRow } from "./Task";

type Props = {
  disabled?: boolean;
  onAttempt?: () => void;
  onSuccess?: (task: Task) => void;
  onFailure?: (reason: PostgrestError) => void;
};

export const TaskCreate = ({
  disabled,
  onAttempt = () => {},
  onSuccess = () => {},
  onFailure = () => {},
}: Props) => {
  const createEmptyTask = async () => {
    onAttempt();

    const { data, error } = await client
      .from("tasks")
      .insert({})
      .select()
      .single();

    if (data) {
      onSuccess(fromDBRow(data));
    } else if (error) {
      onFailure(error);
    }
  };

  return (
    <button type="button" disabled={disabled} onClick={createEmptyTask}>
      Create Task
    </button>
  );
};
