import { Validated, Valid, isValid } from "../Validated";

export type Fields<I, V extends I = I> = {
  [K in keyof I]: Validated<I[K], V[K]>;
};

export const setField =
  <I, K extends keyof I, V extends I = I>(
    key: K,
    value: Validated<I[K], V[K]>,
  ) =>
  (fields: Fields<I, V>): Fields<I, V> => ({
    ...fields,
    [key]: value,
  });

export type ValidFields<V> = {
  [K in keyof V]: Valid<V[K]>;
};

export const areValid = <I, V extends I = I>(
  fields: Fields<I, V>,
): fields is ValidFields<V> =>
  Object.values<Validated<unknown>>(fields).every(isValid);
