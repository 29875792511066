import React from "react";
import { ControlProps } from "./ControlProps";
import { Password, validate } from "./Password";

interface PasswordControlProps extends ControlProps<string, Password> {
  className?: string;
}

export const PasswordControl = ({
  value,
  onChange,
  ...props
}: PasswordControlProps) => (
  <input
    type="password"
    value={value.value}
    onChange={(event) => onChange(validate(event.target.value))}
    {...props}
  />
);
