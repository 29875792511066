import classNames from "classnames";
import { format } from "date-fns/fp";
import React, { ForwardedRef, MouseEventHandler, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { ControlProps } from "./ControlProps";
import { valid } from "./Validated";
import styles from "./DateControl.module.css";
import "react-datepicker/dist/react-datepicker.css";

const displayDate = format("yyyy-MM-dd");

interface InputProps {
  value?: Date | null;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label: string;
}

const Input = forwardRef(
  (
    { value = null, onClick = () => {}, label }: InputProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button className={styles.label} onClick={onClick} ref={ref}>
      {label}
      {value ? `: ${displayDate(value)}` : null}
    </button>
  ),
);

interface Props extends ControlProps<Date | null> {
  className?: string;
  label: string;
}

export const DateControl = ({
  value,
  onChange,
  className,
  label,
  disabled,
}: Props) => {
  return (
    <div className={classNames(styles.root, className)}>
      <DatePicker
        customInput={<Input label={label} />}
        disabled={disabled}
        onChange={(date) => onChange(valid(date))}
        selected={value.value}
        wrapperClassName={styles.input}
        withPortal
      />

      {value.value !== null ? (
        <button
          className={styles.clearButton}
          onClick={() => onChange(valid(null))}
        >
          ❌
        </button>
      ) : null}
    </div>
  );
};
