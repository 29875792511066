import { Database } from "../supabase/types/database";
import { Id } from "./Id";

export type Task = {
  completedAt: Date | null;
  completedById: Id | null;
  createdAt: Date;
  createdById: Id;
  description: string;
  dueAt: Date | null;
  id: Id;
  scheduledAt: Date | null;
  title: string;
};

export const fromDBRow = (
  row: Database["public"]["Tables"]["tasks"]["Row"],
): Task => ({
  completedAt: row.completed_at ? new Date(row.completed_at) : null,
  completedById: row.completed_by_id,
  createdAt: new Date(row.created_at),
  createdById: row.created_by_id,
  description: row.description,
  dueAt: row.due_at ? new Date(row.due_at) : null,
  id: row.id,
  scheduledAt: row.scheduled_at ? new Date(row.scheduled_at) : null,
  title: row.title,
});

export const equals = (a: Task, b: Task): boolean =>
  a.completedAt?.toISOString() === b.completedAt?.toISOString() &&
  a.completedById === b.completedById &&
  a.description === b.description &&
  a.dueAt?.toISOString() === b.dueAt?.toISOString() &&
  a.scheduledAt?.toISOString() === b.scheduledAt?.toISOString() &&
  a.title === b.title;
