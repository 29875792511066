import React, { MouseEventHandler, useCallback, useMemo } from "react";
import { ControlProps } from "../ControlProps";
import { BooleanControl } from "../BooleanControl";
import { Validated, invalid, isValid, valid } from "../Validated";
import styles from "./CompletedControl.module.css";
import classNames from "classnames";

interface Props extends ControlProps<Date | null> {
  className?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const CompletedControl = ({
  className,
  value,
  onChange,
  onClick = () => {},
}: Props) => {
  const inputValue = useMemo(
    () =>
      isValid(value)
        ? valid(!!value.value)
        : invalid(!!value.value, value.reason),
    [value],
  );

  const handleChange = useCallback(
    (value: Validated<boolean>) =>
      onChange(valid(value.value ? new Date() : null)),
    [onChange],
  );

  return (
    <BooleanControl
      className={classNames(styles.root, className)}
      value={inputValue}
      onChange={handleChange}
      onClick={onClick}
    />
  );
};
